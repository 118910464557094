<template>
  <div class="px-3">
    <ul class="tabs">
      <li class="tab"
           v-for="(item, index) in tabs"
           :class="{'active': active===index}"
           @click="activeTab(index)"
           :key="index"
           :style="{width: tabWidth}">{{ item.name }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
import eventLogger from '../services/eventLogger';
export default {
  name: "TheTabs",
  props: {
    tabs: {
      type: Array
    },
    active_index: {
      type: Number,
      default: 0
    },
  },
  created(){
    if(this.active_index ){
      this.active = this.active_index;
    }
  },
  data() {
    return {
      active: 0,
    }
  },
  computed: {
    tabWidth() {
      return this.tabs.length > 0 ? 100 / this.tabs.length + '%' : 0;
    }
  },
  methods: {
    activeTab(index) {
      this.active = index;
      
      const selectedTab = this.tabs[index];

      if(selectedTab.name === "Действующие") {
        eventLogger('select_current_contract')
      } else if(selectedTab.name === "Истекшие") {
        eventLogger('select_expiring_contract')
      } else if(selectedTab.name === "Мои подарки") {
        eventLogger('select_benefits')
      }
      
      this.$emit('active-tab', index);
    }
  }
}
</script>

<style lang="scss">
@import "src/sass/variables";

.tabs {
  background: #FFFFFF;
  border: 1px solid #F1F2F6;
  border-radius: 12px;
  width: 100%;
  display: flex;
  padding: 2px 3px;
  margin-bottom: 20px;

  .tab {
    font-weight: bold;
    font-size: 12px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &.active {
      background: $primary-color;
      box-shadow: 0 12px 16px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      color: #fff;
    }
  }
}
</style>
